<template>
  <div class="aws-container">
      <div class="aws-inner-container">
            <div class="aws-input-container" style="margin-bottom: 0">
                <input id="awsAddress" class="aws-search-address" type="text" :placeholder="placeHolder" v-model="searchAddress.userInput">
                <div class="custom-button-container">
                    <button @click="customAddressToggle()" v-if="toggleCustomAddressButton">Custom Address</button>
                </div>
            </div>

            <div class="auto-complete-result-container">
                <div class="autocomplete-result" id="autocomplete-result" v-if="searchAddress.userInput.length > 3">
                    <p @click="SelectAddress(index)" v-for="(autoCompleteResult, index) in autoCompleteData" :key="autoCompleteResult"><i class="fa fa-map-pin"></i>{{autoCompleteResult.Place.Label}}</p>
                    <p @click="customAddressToggle()" v-show="searchAddress.userInput.length > 3">No results found? Click here.</p>
                    <!-- v-show="autoCompleteData.length <= 2" -->
                </div>
            </div>
      </div>
  </div>
</template>

<script>
/**
 * AWS Location Services Component
 * Creates dropdown with address suggestion
 * */
import Location from "aws-sdk/clients/location";
import {Auth} from 'aws-amplify';
export default {
    props: ['placeHolder'],
    data(){
        return{
            searchAddress:{
                userInput: ""
            },
            autoCompleteData: {},
            addressDataInput: {},
            toggleCustomAddressButton: false
        }
    },
    methods:{
        /**
         * Calls searchPlaceIndexForText to get address data
         * If data is returned set autoCompleteData
         */
        async getLocationData(){
            const credentials = await Auth.currentCredentials();

            const locationClient = new Location({
                credentials,
                region: 'us-east-1'
            });

            const params = {
                IndexName: "lrex-place",
                Text: this.searchAddress.userInput,
                //Text: "233 Washington St, Newark, NJ, 07102, USA",
                FilterCountries: ["USA"],
                BiasPosition: [-74.1724, 40.7357],
                MaxResults: 5
            };

            if(this.searchAddress.userInput.length > 1){

                locationClient.searchPlaceIndexForText(params,(err,data)=>{
                    if(err){
                        alert("Error with Location Services.")
                    }
                    if(data){
                        //Only suggest addresses with Street and AddressNumber Values
                        if(data.Results[0]){
                            if(data.Results[0].Place.AddressNumber || data.Results[0].Place.Street){
                                this.autoCompleteData = data.Results;
                            }
                        }
                    }
                })
            }
        },
        /**
         * Set addressDataInput to returned data from autoCompleteData
         * autoCompleteData[index].Place returns address data
         * autoCompleteData[index].Relevance return relevance compared to user input
         */
        SelectAddress(index){
            let selectedAddressData = this.autoCompleteData[index].Place;
            //User selected this address set relevance to 1
            let selectedAddressRelevance = 1;

            if(selectedAddressData.AddressNumber && selectedAddressData.Street){
                this.addressDataInput.address1 = selectedAddressData.AddressNumber + " " + selectedAddressData.Street;
            }else if(selectedAddressData.AddressNumber && !selectedAddressData.Street){
                this.addressDataInput.address1 = selectedAddressData.AddressNumber;
            }else if(!selectedAddressData.AddressNumber && selectedAddressData.Street){
                this.addressDataInput.address1 = selectedAddressData.Street;
            }else{
                this.$emit('GetSelectedAddressData', false);
            }

            if(selectedAddressData.Municipality){
                this.addressDataInput.city = selectedAddressData.Municipality;
            }else{
                this.$emit('GetSelectedAddressData', false);
            }

            if(selectedAddressData.PostalCode){
                this.addressDataInput.zipCode = selectedAddressData.PostalCode.substring(0, 5);
                this.addressDataInput.plusFour = selectedAddressData.PostalCode.substring(6, 10);
            }else{
                this.$emit('GetSelectedAddressData', false);
            }

            if(selectedAddressData.Geometry){
                this.addressDataInput.latitude = selectedAddressData.Geometry.Point[1];
                this.addressDataInput.longitude = selectedAddressData.Geometry.Point[0];
            }else{
                this.$emit('GetSelectedAddressData', false);
            }

            if(selectedAddressRelevance){
                this.addressDataInput.relevance = selectedAddressRelevance;
            }else{
                this.$emit('GetSelectedAddressData', false);
            }

            switch(selectedAddressData.Region){
                case "Alabama":
                    this.addressDataInput.state = "AL";
                    break;
                case "Alaska":
                    this.addressDataInput.state = "AK";
                    break;
                case "Arizona":
                    this.addressDataInput.state = "AZ";
                    break;
                case "Arkansas":
                    this.addressDataInput.state = "AR";
                    break;
                case "California":
                    this.addressDataInput.state = "CA";
                    break;
                case "Colorado":
                    this.addressDataInput.state = "CO";
                    break;
                case "Florida":
                    this.addressDataInput.state = "FL";
                    break;
                case "Georgia":
                    this.addressDataInput.state = "GA";
                    break;
                case "Hawaii":
                    this.addressDataInput.state = "HI";
                    break;
                case "Idaho":
                    this.addressDataInput.state = "ID";
                    break;
                case "Illinois":
                    this.addressDataInput.state = "IL";
                    break;
                case "Indiana":
                    this.addressDataInput.state = "IN";
                    break;
                case "Iowa":
                    this.addressDataInput.state = "IA";
                    break;
                case "Kansas":
                    this.addressDataInput.state = "KS";
                    break;
                case "Kentucky":
                    this.addressDataInput.state = "KY";
                    break;
                case "Louisiana":
                    this.addressDataInput.state = "LA";
                    break;
                case "Michigan":
                    this.addressDataInput.state = "MI";
                    break;
                case "Minnesota":
                    this.addressDataInput.state = "MN";
                    break;
                case "Mississippi":
                    this.addressDataInput.state = "MS";
                    break;
                case "Missouri":
                    this.addressDataInput.state = "MO";
                    break;
                case "Montana":
                    this.addressDataInput.state = "MT";
                    break;
                case "Nebraska":
                    this.addressDataInput.state = "NE";
                    break;
                case "Nevada":
                    this.addressDataInput.state = "NV";
                    break;
                case "New Mexico":
                    this.addressDataInput.state = "NM";
                    break;
                case "North Carolina":
                    this.addressDataInput.state = "NC";
                    break;
                case "North Dakota":
                    this.addressDataInput.state = "ND";
                    break;
                case "Ohio":
                    this.addressDataInput.state = "OH";
                    break;
                case "Oklahoma":
                    this.addressDataInput.state = "OK";
                    break;
                case "Oregon":
                    this.addressDataInput.state = "OR";
                    break;
                case "South Carolina":
                    this.addressDataInput.state = "SC";
                    break;
                case "South Dakota":
                    this.addressDataInput.state = "SD";
                    break;
                case "Tennessee":
                    this.addressDataInput.state = "TN";
                    break;
                case "Texas":
                    this.addressDataInput.state = "TX";
                    break;
                case "Utah":
                    this.addressDataInput.state = "UT";
                    break;
                case "Vermont":
                    this.addressDataInput.state = "VT";
                    break;
                case "Washington":
                    this.addressDataInput.state = "WA";
                    break;
                case "West Virginia":
                    this.addressDataInput.state = "WV";
                    break;
                case "Wisconsin":
                    this.addressDataInput.state = "WI";
                    break;
                case "Wyoming":
                    this.addressDataInput.state = "WY";
                    break;
                case "District of Columbia":
                    this.addressDataInput.state = "DC";
                    break;
                case "Delaware":
                    this.addressDataInput.state = "DE";
                    break;
                case "Massachusetts":
                    this.addressDataInput.state = "MA";
                    break;
                case "Maryland":
                    this.addressDataInput.state = "MD";
                    break;
                case "Maine":
                    this.addressDataInput.state = "ME";
                    break;
                case "New Hampshire":
                    this.addressDataInput.state = "NH";
                    break;
                case "New Jersey":
                    this.addressDataInput.state = "NJ";
                    break;
                case "New York":
                    this.addressDataInput.state = "NY";
                    break;
                case "Pennsylvania":
                    this.addressDataInput.state = "PA";
                    break;
                case "Rhode Island":
                    this.addressDataInput.state = "RI";
                    break;
                case "Virginia":
                    this.addressDataInput.state = "VA";
                    break;
                default:
                    //alert("Error with State Input")
                    this.$emit('GetSelectedAddressData', false);
            }
            
            this.$emit('GetSelectedAddressData', this.addressDataInput);
            this.searchAddress.userInput = "";
        },
        customAddressToggle(){
            this.$emit('customAddressToggle', true);
        }
    },
    /**
     * Call AWS location to get address suggestion when user types in input
     */
    watch:{
      'searchAddress.userInput': function(){
          if(this.searchAddress.userInput.length > 3){
            this.getLocationData();
            this.toggleCustomAddressButton = true;
          }
        }
    },
    mounted(){
        //Close suggestion box when user clicks outside of it
            var vueInstance = this;
            document.addEventListener('click', function clickOutsideAutoComplete(event){
                const autocompleteBox = document.getElementById('awsAddress');
                if(vueInstance.searchAddress.userInput.length > 0){
                    if(!autocompleteBox.contains(event.target)){
                        vueInstance.searchAddress.userInput = "";
                    }
                }
            })
        }
}
</script>

<style scoped>

    .aws-input-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .custom-button-container{
        margin: 1px;
    }

    .custom-button-container button{
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        padding-right: 5px;
        padding-left: 5px;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-size: 12px;
        color: #fff;
        background-color: #32ccfe;
    }

    .aws-search-address{
        padding: 10px 0px 10px 10px;
        font-size: 15px;
        border: 1px solid rgba(0, 0, 0, 0.336);
        border-radius: 5px;
        outline: none;
        width: 100%;
        margin-bottom: 0;
    }

    /* AWS Location Service */
    .fa-map-pin{
        color: #999;
        margin-right: 5px;
        font-size: 1em;
    }

    .autocomplete-result{
        background-color: #fff;
        border-radius: 5px;
        text-align: left;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.062);
        margin-bottom: 10px;
        position: absolute;
        z-index: 10;
    }

    @keyframes animate-result {
        from{
            margin-top: -5px;
        }
        to{
            margin-top: 0;
        }
    }

    .autocomplete-result p{
        cursor: pointer;
        transition-duration: .5s;
        margin: 0;
        padding: 10px;
        font-size: 12px;
        animation: animate-result .5s ease;
        border-bottom: 1px solid rgb(235, 235, 235);
    }

    .autocomplete-result p:hover{
        background-color: rgb(235, 235, 235);
        transition-duration: .5s;
    }
</style>