/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
    }
  }
`;
