<template>
<div class="main-container">
    <div class="inner-container">
        <div class="lrex-shape-text-container">
            <div class="text-container">
                <router-link class="styled-header" to="ContractorServices"><h2>Click to Get in touch</h2></router-link>
                <p>
                    We have several territories available for business partners in NJ, DE, and PA.
                </p>

                <h2>Who are we?</h2>
                <p>
                    We are a delivery enterprise with 100 years of shipping experience, and we want to work with your business. 
                    We are growing rapidly, with roots in New Jersey, our vision is to reach across the east coast and the nation
                    with businesses like yours. Our philosophy is simple. When small businesses come together, we are stronger.
                </p>

                <h2>Rates</h2>
                <p>
                    Dedicated Routes are negotiated based on the size, complexity, and the number of shipments on the route. 
                    Larger the volume, the more revenue potential.
                </p>
            </div>

            <img class="lrex-shape-driver" src="../../assets/FinalImages/LREXshapeDriverWoman.png" alt="">
                
        </div>
        

        <div class="list-container">
            <div class="header-list-contiainer">
              <h2>Scope of Work</h2>  
            </div>
            
            <div class="inner-list-container">
                <ul>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Deliver shipments accurately and timely. 98-99% On Time and On Point Performance is required for all vendors.
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Pick-up shipments. Arrive during the scheduled window and secure shipments for return to one of our designated warehouse locations.
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Capture Photo Proof of Delivery 
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Handle with care - Businesses must follow the delivery instructions included on the label. (Cold storage, signature, priority)
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Lifting – Most of the packages are 5lbs but our network offers up to 50lbs.
                        </li>
                    </div>
                    <!-- <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Cold Storage and Specialty Handling: All packages require care, but some are 
                            temperature-controlled products. special equipment and instructions must be followed for the most sensitive deliveries.
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Lifting – Most of the packages are 5lbs but our network offers up to 50lbs.
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Pick Up – Your business also needs to support scheduled pickups. 
                            You or your workers are required to arrive on time and secure pickups for return to one of our designated warehouse locations.
                        </li>
                    </div>
                    <div class="list-shape-container">
                        <div class="list-shape"></div>
                        <li>
                            Experience with other shippers preferred but we will consider new businesses.
                        </li>
                    </div> -->
                </ul>
            </div>

            <div class="lrex-shape-text-container">
                <img class="lrex-shape-hand-box" src="../../assets/FinalImages/LREXshapeHandingBox.png" alt="">
                <div>
                    <h2>Business Eligibility</h2>
                    <p>To be awarded contract, businesses must have:</p>
                    <div class="inner-list-container-needs">
                        <ul>
                            <div class="list-shape-container"><div class="list-shape"></div><li>Registered Business with EIN</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>Reliable, clean, fuel-efficient vehicle or fleet</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>WE ENFORCE SAFETY - MVR CHECK WILL BE PERFORMED BEFORE THE EXECUTION OF ALL CONTRACTS AND THROUGHOUT ITS DURATION. YOUR DRIVERS MUST HAVE A PROVEN HISTORY OF SAFE DRIVING. OTHER BACKGROUND CHECKS MAY BE REQUIRED FOR SPECIFIC CONTRACTS.</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>Commercial Auto Insurance – 300K Combined Single Limit is the minimum required coverage for an LRex contract to be awarded. Other liability insurance may be requested depending on contract requirements. </li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>Customer Service - Your business must align with our customer-centric values. Customer Service escalation plan is mandatory.</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>HIPPA trained drivers preferred</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>Experience with other shippers preferred but we will consider new businesses.</li></div>
                            <div class="list-shape-container"><div class="list-shape"></div><li>We support partnering with Women and Minority owned businesses.</li></div>
                        </ul>
                </div>
                </div>
                
            </div>
        </div>

        <h2>What We Do</h2>
        <p>Our talented <strong>Business Development</strong> team brings new customers to us. As customers join our network our routes expand and so does your revenue potential. Many of our current business owners manage multiple territories and drivers.<br>
            Our <strong>Care team</strong> team supports you every mile of the way. We know every delivery isn’t easy. Our trained agents are able to help you resolve difficult deliveries. <br>
            <strong>Technology</strong> – Our app guides you efficiently to each destination with built in photo and signature capabilities.<br>
            <strong>Warehouse Locations</strong> – We have multiple locations in Union, Cherry Hill, and Englewood Cliffs. We shuttle work to our delivery areas making it easier for our contractors to launch each day.<br>
        </p>

        <h2 class="final-styled-header">Come join our mission.</h2>
        
    </div>
</div>
</template>

<script>
export default {
    methods:{
        scrollToTop(){
            window.scrollTo(0,0);
        }
    },
    mounted(){
        this.scrollToTop();
    }
}
</script>

<style scoped>
    .main-container{
        display: flex;
        justify-content: center;
        animation: animate-container 1s ease;
    }

    @keyframes animate-container{
        from{margin-left: -10%}
        to{margin-left: 0;}
    }

    .inner-container{
        width: 80%;
        text-align: left;
    }

    .header-list-contiainer{
        width: 100%;
        text-align: left;
    }

    .inner-list-container-needs{
        padding-bottom: 10%;
        border-bottom: 1px solid #ffcccc;
    }

    .list-shape{
        min-width: 15px;
        width: 15px;
        height: 15px;
        background-color: #33f18a;
        border-radius: 50px;
        margin-top: 10px;
        margin-right: 10px;
    }

    .list-shape-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ul{
        list-style: none;
    }

    li{
        margin-top: 10px;
        padding: 5px 15px;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .inner-list-container{
        border-bottom: 1px solid #ffcccc;
    }

    .styled-header{
        text-decoration: none;
        cursor: pointer;
    }

    .styled-header h2:hover{
        background-color: #e97446;
        transition-duration: .25s;
    }

    .styled-header h2{
        transition-duration: .25s;
        color:#ffffff;
        background-color: #fe804d;
        text-align: center;
        padding: 5px;
        width: 30%;
        border-radius: 200px;
    }

    .final-styled-header{
        color:#ffffff;
        background-color: #32ccfe;
        text-align: center;
        padding-top: 2vw;
        padding-bottom: 2vw;
        padding-left: 2vw;
        padding-right: 2vw;
        width: 20%;
        clip-path: polygon(15% 5%, 85% 5%, 100% 50%, 85% 95%, 15% 95%, 0% 50%);
    }

    .text-container{
        width: 70%;
    }

    .lrex-shape-text-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .lrex-shape-driver{
        width: 30%;
        height: 30%;
        filter: drop-shadow(5px 10px #33f18a);
    }

    .lrex-shape-hand-box{
        width: 25%;
        height: 25%;
        filter: drop-shadow(15px 10px #f4b204);
    }

    @media screen and (max-width: 1000px) {

        .inner-container{
            width: 90%;
        }

        .header-list-contiainer{
            width: 90%;
        }

        .final-styled-header{
            width: 200px;
        }

        .styled-header h2{
            width: 200px;
        }

        .text-container{
            width: 90%;
        }

        .lrex-shape-text-container{
            flex-direction: column;
        }

        .lrex-shape-driver{
            display: block;
            margin: auto;
            width: 70%;
            height: 70%;
            filter: drop-shadow(5px 10px #33f18a);
        }

        .lrex-shape-hand-box{
            display: block;
            margin: auto;
            width: 60%;
            height: 60%;
            filter: drop-shadow(15px 10px #f4b204);
        }
    }
</style>