<template>
<div class="main-container">
    <div class="inner-container">
        <h2 class="styled-header">Terms and Conditions</h2>

        <h2>PLEASE READ CAREFULLY BEFORE USING THIS WEB SITE</h2>
        <p>
            By using this Website or any other website owned, operated, licensed, or controlled by LREX, you signify your assent to these terms and conditions 
            of use. If you do not agree to these terms and conditions of use, please do not use this Site. We reserve the right, at our discretion, to change, 
            modify, add, or delete portions of these terms and conditions at any time, without prior notice. All changes will be updated and posted on this page.
             Your continued use of this Site or any other LREX site following the posting of changes to these terms and conditions will mean you accept those changes.
        </p>

        <h2>Registration</h2>
        <p>
            You agree to 
            <br>(a) provide true, accurate, current and complete information about yourself, including mailing and email address, as prompted by the registration form and 
            <br>(b) maintain and promptly update the registration data to keep it true, accurate, current and complete. If you provide any information that is untrue, 
            inaccurate, not current or incomplete or LREX has reasonable grounds to suspect that such information provided is untrue, inaccurate, not current or incomplete, 
            LREX has the right to suspend or terminate your account and refuse any and all current or future use of the service (or any portion thereof). Additionally, LREX 
            may terminate your access to the Website, and its services, at any time, without notice, for any reason or no reason at all, so long as such reason is not otherwise 
            prohibited by applicable law.
        </p>

        <h2>Credit Purchases, Invoices and Payments</h2>
        <p>
            Failure to provide required Registration information will result in LREX rejecting an application for credit and other billing considerations or arrangements. All credit 
            requests are subject to review, and acceptance or rejection by LREX, which decision is final in its sole discretion. LREX reserves the right to reject a subscriber for 
            credit or other billing consideration for any cause and without reason. By making a purchase, whether by use of an accepted credit card or through the extension of credit 
            to you by LREX, you agree to, represent and warrant the following 
            <br>(i) any credit information you supply is true and complete; 
            <br>(ii) you are authorized to use whatever 
            credit card you use for payment; and 
            <br>(iii) to pay all amounts accrued in your account, as invoiced, at the prevailing rates and customary charges posted thereon 
            (including charges incurred by others to whom you have given access of your account), together with a finance charge on all unpaid balances which remain unpaid after 
            15 days from the invoice date. Said finance charge shall accrue at the greater of 1.5% per month, or the highest rate permitted by law; and shall continue to accrue 
            monthly on any and all unpaid balances until payment in full is received by LREX. Your ability to purchase is also subject to limits established by LREX or your credit 
            card issuer. In the event LREX is required to bring legal action against you for the collection of unpaid balances due and owing to LREX, in addition to such unpaid 
            balances and accrued finance charges, you agree to be responsible for LREX's costs and fees necessary and incident to such legal action, including reasonable attorney fees. 
            LREX may, in its sole discretion, decline service to you or terminate your account without notice for your failure to make timely payment.
        </p>

        <h2>Authorized Users and Permitted Access</h2>
        <p>
            Only You and your employees, temporary employees, student interns, associates, partners/members, and contractors dedicated to performing work exclusively for you (to the 
            extent those categories of persons are appropriate to your situation) or others to whom you grant specific permission are eligible to access and use the LREX Website 
            ("Eligible Persons"). The term "Authorized User" means an Eligible Person whom you have identified to LREX for purposes of issuing an LREX User ID and Password (hereafter 
            "LREX ID") and in connection with your LREX Monthly Billing Account. Each Eligible Person who wishes to access the LREX Website must have a unique LREX ID and thus become 
            an Authorized User. You agree that each LREX ID may only be used by the Authorized User to whom LREX assigns and that the LREX ID may not be shared with or used by any other 
            person, including other Authorized Users. You will manage your roster of Authorized Users and will promptly notify LREX, either through the online account management utilities 
            created for that purpose or by direct customer service communication, to deactivate an Authorized User's LREX ID if the Authorized User is no longer an Eligible Person or 
            you otherwise wish to terminate the Authorized User's access to the LREX Website and Materials. YOU ARE RESPONSIBLE FOR ALL USE OF THE LREX WEBSITE ACCESSED WITH LREX IDS 
            ISSUED TO YOUR AUTHORIZED USERS WHO ARE CONNECTED TO YOUR LREX MONTHLY BILLING ACCOUNT, INCLUDING ASSOCIATED CHARGES AND SUR-CHARGES, WHETHER INCURRED BY AUTHORIZED USERS 
            OR OTHERS. You will use reasonable commercial efforts to prevent unauthorized use of LREX IDs assigned to your Authorized Users and will promptly notify LREX, in writing, 
            if you suspect that such an LREX ID is lost, stolen, compromised, or misused.
        </p>

        <h2>Restrictions on Use of Materials</h2>
        <p>
            Unless otherwise noted, all materials, including images, illustrations, designs, icons, photographs, video clips, and other materials that are uniquely part of this web site 
            or any other LREX web site are copyrights, trademarks, trade dress and/or other intellectual properties owned, controlled or licensed by LREX. No material from this web site may 
            be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for 
            your personal, non-commercial use only, provided you keep intact all copyright and other proprietary notices.
            <br>
            Modification of the materials or use of the materials for any other purpose is a violation of LREX's copyright and other proprietary rights. For purposes of these terms, the use of 
            any such material on any other web site or networked computer environment is prohibited. All trademarks, service marks, and trade names are proprietary to LREX. Any conduct, which 
            restricts or inhibits any other user from using the site, or interferes with the use of the site by any other user, is a violation of these terms and conditions.
        </p>

        <h2>Discontinuing Web Site Use</h2>
        <p>
            These terms are effective until terminated by either party. You may terminate these terms at any time by destroying all materials obtained from any and all LREX Web Site(s) and all 
            related documentation and all copies and installations thereof, whether made under these terms or otherwise. LREX may terminate any User Account(s) and associated User IDs immediately 
            without notice from LREX if in LREX's sole discretion you fail to comply with any term or provision of these terms. Upon termination, you must destroy all materials obtained from this 
            site and any and all other LREX Web Site(s) and all copies thereof, whether made under the terms of these terms or otherwise. LREX may terminate the account of any user that engages 
            in any conduct tending to interfere with, or interrupt, operation of the web site, as determined solely by LREX.
        </p>

        <h2>Delays</h2>
        <p>
            LREX.com services and information is subject to interruption and delay. The User acknowledges that this is not an error free environment and that some forms of electronic transactions 
            need substantial lead-time for the transmissions of information. LREX.com shall not be liable to the User for interruption and delays.
        </p>

        <h2>Indemnification</h2>
        <p>
            User will indemnify and hold harmless New Jersey Lawyers Service, LLC, its members, directors, officers, employees, and agents, as well as all such equivalents at any parent or subsidiary 
            company from and against any and all direct or indirect losses, claims, demands, expenses (including attorneys' fees), or liabilities of whatever nature or kind arising out of User's access
             to the services, use of or distribution of information provided by the LREX Website.
        </p>

        <h2>Disclaimer</h2>
        <p>
            THE MATERIALS, INFORMATION AND FUNCTIONALITY ON AND CONCERNING THE LREX WEB SITE IS PROVIDED "AS IS" AND "AS AVAILABLE", WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE 
            FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, LREX DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS 
            FOR A PARTICULAR PURPOSE.
            <br>
            NOTHWITHSTAND ANYTHING TO THE CONTRARY, LREX WILL NEVER BE LIABLE FOR ANY SPECIAL CONSEQUENTIAL OR INCIDENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS OR PROFIT OPPORTUNITY, 
            IN CONNECTION WITH ANY LIABILITY IT MAY OTHERWISE INCURR. LREX LIABILITY IS ALWAYS LIMITED TO THE AMOUNT CHARGED BY LREX FOR THE SERVICE RENDERED, AND NO MORE, UNLESS A HIGHER AMOUNT IS 
            SPECIFICALL STATED OR AGREED TO IN WRITING.
            <br>
            Additional disclaimers and limitations of liability and damages apply to all Shipments and other services we provide. Please see the Shipment Terms and Conditions for additional provisions.
        </p>

        <h2>Force Majeure</h2>
        <p>
            Our delivery of services through the LREX Website is subject to interruption and delay which may be due to causes beyond our reasonable control, such as acts of God, acts of any government, 
            war or other hostility, civil disorder, the elements, fire, explosion, power failure, failure of equipment not under the control of either party, transmission line or communications failure
            or unavailability, industrial or labor dispute, inability to obtain necessary supplies and the like.
            <br>
            In no event will LREX be responsible or liable for: 
            <br>(1) any problem relating to telephone lines or other Internet transmission connection not entirely under our control; 
            <br>(2) the unavailability of telephone lines or other electronic transmission lines or devices not under our control that results in your inability to reach LREX.com for the intended purpose; 
            <br>(3) transmission errors resulting from or in connection with any hardware or transmission not under our control or error not due to our willful or reckless act; 
            <br>(4) any alteration or destruction of Documents resulting from third parties' unauthorized access to LREX.com except to the extent that such access and alteration or destruction of information results directly from our willful or reckless act 
            <br>(5) any losses or damages or alteration or destruction of information on your computer system or elsewhere resulting from the transmission of computer viruses or other damaging or destructive software or software components by us or through LREX.com, except in the case of our willful or reckless act.
        </p>

        <h2>Links to Other Web Sites and Services</h2>
        <p>
            To the extent that this Web site and any other LREX Website(s) contain links to outside services and resources, LREX does not control the availability and content of those outside services and resources. Any concerns regarding any such service or resource, or any link thereto, should be directed to the 
            particular service or resource.
        </p>

        <h2>Waiver, Jurisdiction and Venue</h2>
        <p>
            The failure of you, LREX, or any third party supplier of materials or information to enforce any provision hereof shall not constitute or be construed as a waiver of such provision or of the right to enforce it at a later time.
            <br>
            These terms and conditions shall be governed by and construed in accordance with the laws of the State of New Jersey. You agree that any action at law or in equity arising out of or relating to these terms and conditions shall 
            be filed only in the State or Federal Court located in Essex County, New Jersey and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action. If any provision of these 
            terms and conditions shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity or enforceability of any remaining provisions.
        </p>

        <h2>Not Legal Advice</h2>
        <p>
            The information contained at this Web site and any other LREX Website has been prepared by LREX as a service to its Users and the Internet community and is not intended to constitute legal advice. LREX has used reasonable efforts in 
            collecting, preparing and providing quality information and material, but does not warrant or guarantee the accuracy, completeness, adequacy or currency of the information contained in or linked to this Website or any other LREX Website. 
            Users of information from this Website or any other LREX Website or links do so at their own risk.
            <br>
            Any reference made by this Website or any other LREX Website(s) to any specific commercial product, process, or service (or provider of such product, process or service) by trade name, trademark, hyperlink, or otherwise, does not 
            constitute or imply an endorsement, recommendation, or favoring by LREX. Content on this and any other LREX Website(s) may be provided by third parties and users. Any opinions, advice, 
            statements, services, offers or other information expressed or made available by third parties, including information providers, users or others, are those of the respective author(s) 
            or distributor(s) and do not necessarily state or reflect those of LREX.
            <br>
            Because an attorney/client relationship is not created by use of LREX, any communication with LREX or its content providers does not establish an attorney/client relationship and/or privilege.
        </p>
        
    </div>
</div>
</template>

<script>
export default {
    methods:{
        scrollToTop(){
            window.scrollTo(0,0);
        }
    },
    mounted(){
        this.scrollToTop();
    }
}
</script>

<style scoped>
    .main-container{
        display: flex;
        justify-content: center;
    }

    .inner-container{
        width: 80%;
        text-align: left;
    }

    .inner-list-container{
        border-bottom: 1px solid #ffcccc;
    }

    .styled-header{
        color:#ffffff;
        background-color: #fe804d;
        text-align: center;
        padding-top: 2vw;
        padding-bottom: 2vw;
        width: 20%;
        border-radius: 200px;
    }

    .final-styled-header{
        color:#ffffff;
        background-color: #32ccfe;
        text-align: center;
        padding-top: 2vw;
        padding-bottom: 2vw;
        width: 20%;
        clip-path: polygon(15% 5%, 85% 5%, 100% 50%, 85% 95%, 15% 95%, 0% 50%);
    }

    @media screen and (max-width: 1000px) {

        .inner-container{
            width: 90%;
        }

        .styled-header, .final-styled-header{
            width: 200px;
        }
    }
</style>