/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        header
        description
        imageBase64
        urlLink
        businessRelated
        shippingRelated
        marketingRelated
        dailyUpdateRelated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
