<template>
    <div class="track_package">
        <img class="delivery-truck-img" v-show="AlternateHeader == 1" src="../assets/Delivery-Truck.jpg" alt="Truck">
        <img v-show="AlternateHeader == 2" src="../assets/FinalImages/Stocksy_Delivery_Driver_Back_of_Van.jpg" alt="">
        <img v-show="AlternateHeader == 3" src="../assets/FinalImages/Stocksy_Delivery_signature.jpg" alt="">
        <div class="form_shiptrack">
            <h1>Your shipping partner.</h1>
            <p class="njls-dba-header">NJLS doing business as LREX</p>
            <p>Track a package</p>
            <form id="shipmentForm" class="shipmentForm" @submit.prevent="ShipmentTrackingTogglePopup('ShipmentTrackingButtonTrigger');">
                    <input placeholder="Enter Shipment ID" type="text" name="shipment" v-model="posts.shipmentId">
            </form>
        </div>
    </div>

    <div class="lrex_paragraph">
      <p>
        <strong>When your shipping needs are more than moving your package from Point A to Point B.</strong>
      </p>
      <p style="display: none;">
        <strong>When your shipping needs are more than moving your package from Point A to Point B.</strong> <br/><br>
        Don’t let your ambitions be boxed in. “Beyond the box” is a philosophy that reflects our 
        view on the future of shipping: regional, sustainable, and backed by adaptive technologies 
        and shipping expertise. That’s why we look at every shipment as part of a bigger journey, 
        from before the start to the next destination. We ask: how can we help you get closer to 
        what’s next, for your business?
      </p>
    </div>

  <div class="carouselContainer">
    <div class="arrow_left" @click.prevent="prev"></div>
    <carousel 
    @next = 'next'
    @prev = 'prev'
    >
      <carousel-slide v-for="(textItem,index) in textObjects" :key="textItem" :index="index" :visibleSlide ="visibleSlide" :direction="direction">
        <div class="textSlideContainer">
          <h3 style="font-weight: bold; margin-bottom: 0;">{{textItem.header}}</h3>
          <p style="padding: 5px; margin-top: 0;" class="textSlide">{{textItem.text}}</p>
        </div>
      </carousel-slide>
    </carousel>
    <div class="arrow_right" @click.prevent="next"></div>
  </div>

    <div class="headline_container">
      <div class="headline_article">
        <p>We customize a shipping solution for you, the right process, the right technology, and the right people.</p>
        <p style="display: none;">Our cloud-based shipping platform is designed to evolve, twenty years of technology and a century of shipping 
          experience. Built for complex businesses, legal requirements for courts, & compliance for pharmaceuticals, a 
          shipping partner for your last mile and more.
        </p>
      </div>
      <div class="headline-bubble-container">
        <div class="headline-bubble-inner">
          <svg class="svg-main-test" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 138.88 121.54">
            <defs>
              <linearGradient id="linear-gradient" x1="8.22" y1="52.93" x2="135.22" y2="52.93" gradientTransform="translate(-6.69 55.73) rotate(-25.77)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0"/>
                <stop offset=".05" stop-color="#e2f4fa" stop-opacity=".11"/>
                <stop offset=".18" stop-color="#9edcf0" stop-opacity=".38"/>
                <stop offset=".3" stop-color="#66c8e8" stop-opacity=".6"/>
                <stop offset=".42" stop-color="#3ab9e2" stop-opacity=".77"/>
                <stop offset=".53" stop-color="#1aaedd" stop-opacity=".9"/>
                <stop offset=".62" stop-color="#07a7da" stop-opacity=".97"/>
                <stop offset=".7" stop-color="#01a5da"/>
              </linearGradient>
              <linearGradient id="linear-gradient-2" x1="32.99" y1="79.17" x2="129.05" y2="10.04" gradientUnits="userSpaceOnUse">
                <stop offset=".47" stop-color="#32ccfe"/>
                <stop offset=".52" stop-color="#35ccfe" stop-opacity=".98"/>
                <stop offset=".57" stop-color="#3ecffe" stop-opacity=".94"/>
                <stop offset=".61" stop-color="#4ed2fe" stop-opacity=".86"/>
                <stop offset=".66" stop-color="#63d8fe" stop-opacity=".76"/>
                <stop offset=".7" stop-color="#80dffe" stop-opacity=".62"/>
                <stop offset=".75" stop-color="#a2e8fe" stop-opacity=".45"/>
                <stop offset=".79" stop-color="#ccf2fe" stop-opacity=".25"/>
                <stop offset=".84" stop-color="#fafdfe" stop-opacity=".02"/>
                <stop offset=".84" stop-color="#fff" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="linear-gradient-3" x1="95.51" y1="35.95" x2="140.49" y2="35.95" gradientTransform="translate(134.52 50.79) rotate(141.08)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0"/>
                <stop offset=".09" stop-color="#dbfcea" stop-opacity=".18"/>
                <stop offset=".2" stop-color="#aff9d1" stop-opacity=".39"/>
                <stop offset=".33" stop-color="#89f6bb" stop-opacity=".58"/>
                <stop offset=".45" stop-color="#69f4a9" stop-opacity=".73"/>
                <stop offset=".57" stop-color="#51f39b" stop-opacity=".85"/>
                <stop offset=".69" stop-color="#40f191" stop-opacity=".93"/>
                <stop offset=".81" stop-color="#36f18b" stop-opacity=".98"/>
                <stop offset=".92" stop-color="#33f18a"/>
              </linearGradient>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
              <path class="bubble1" d="M72.31,121.51c24.13-.75,48.13-15.68,59.83-37.05,10.84-19.78,9.51-48.54-10.61-63.02-8.58-6.18-19.35-8.71-29.19-5.75-12.44,3.74-21.6,14.45-31.33,22.54-9.8,8.14-22.43,8.77-32.44,16.16-9.19,6.78-13.65,17.19-11.26,29.07,5.19,25.71,31.84,38.77,55,38.05Z" style="fill: url(#linear-gradient);"/>
              <g class="bubble2">
                <path d="M75.19,113.49c-6.74-.08-13.54-2.69-18.64-7.09-5.68-4.9-6.31-11.08-10.17-17.06-7.74-11.99-23.27-14.5-31.64-25.8C-4.44,37.7,22.92,3.53,50.37,.33c29.49-3.44,57.03,20.17,63.52,47.99,6.07,26.02-6.5,65.54-38.7,65.17Z" style="fill: url(#linear-gradient-2);"/>
                <text transform="translate(14.57 46.35)" style="font-family: 'Work Sans', sans-serif; font-weight: bold; font-size: 10px;"><tspan x="0" y="0" style="letter-spacing: -.07em;">T</tspan><tspan x="4.69" y="0">echnology Shapes </tspan></text>
                <text transform="translate(14.56 58.45)" style="font-family: 'Work Sans', sans-serif; font-weight: bold; font-size: 10px;"><tspan x="0" y="0" style="letter-spacing: 0em;">O</tspan><tspan x="7.61" y="0">ur Shipping</tspan></text>
              </g>
              <path class="bubble3" d="M18.34,79.89c-8.26,2.21-15.33,9.29-17.65,17.6-2.14,7.7,.63,17.51,8.74,20.88,3.46,1.44,7.37,1.45,10.53-.37,3.99-2.29,6.28-6.73,8.99-10.3,2.72-3.6,7.03-4.83,9.89-8.19,2.62-3.08,3.32-7.03,1.54-10.93-3.86-8.45-14.11-10.81-22.04-8.69Z" style="fill: url(#linear-gradient-3);"/>
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="popup-container">

      <ShipmentTrackingPopup 
        v-if="ShipmentTrackingPopupTriggers.ShipmentTrackingButtonTrigger" 
        :ShipmentTrackingTogglePopup="()=> ShipmentTrackingTogglePopup('ShipmentTrackingButtonTrigger')"
        :shipmentIdProp = "posts.shipmentId"
        class="signin-popup"
        id="shipmentTrackingContents">
      </ShipmentTrackingPopup>
    </div>

</template>

<script>
  import Carousel from './Carousel/Carousel.vue'
  import CarouselSlide from './Carousel/CarouselSlide.vue'
  import ShipmentTrackingPopup from './Popups/ShipmentTrackingPopup.vue'
  import {ref} from 'vue';

  export default{
    data: function () {
      return {
          texts: [
          'Who we’re for: Small- and medium-sized businesses in New Jersey and across the tristate area',
          'Custom shipping for businesses of all shapes and sizes.',
          'Going the extra mile for your last mile.',
          'Shipped, delivered, done.'
        ],
        textObjects:[
          {header: 'Who we are for', text: 'You. We are driven by your ambitions.'},
          {header: 'What we do', text: 'Partner with businesses with complex shipping needs like Law, Pharmaceuticals, Payroll, and Banks.'},
          {header: 'How we do it', text: 'Our consultative approach leverages our shipping tech and logistics experience and addresses the macro shipping journey.'},
          {header: 'Where we do it', text: 'Our delivery areas include CT, DE, MA, NJ, NY, PA.'}
          // {header: 'Why we do it', text: 'A belief that the future of shipping requires solutions that do more than just deliver to your destination.'}
        ],
          visibleSlide: 0,
          direction: 'left',
          posts:{
            shipmentId: null,
            IncludeImageURL: true
          },
          AlternateHeader: 1
        }
    },
    methods:{
      next(){
      if(this.visibleSlide >= this.textsLength - 1){
          this.visibleSlide = 0;
        }else{
          this.visibleSlide++;
        } 
        this.direction = "left";
      },
      prev(){
      if(this.visibleSlide <= 0){
          this.visibleSlide = this.textsLength - 1;
        }else{
          this.visibleSlide--;
        } 
        this.direction = "right";
      },
      AlternateHeaderValue(){
        this.AlternateHeader++;
        if(this.AlternateHeader > 3){
           this.AlternateHeader = 1;
         }
        //Alternate Text Capsules
         this.next();
      }
    },
    mounted(){
      setInterval(this.AlternateHeaderValue, 10000);
    },
    computed:{
      textsLength(){
        return this.texts.length;
      }
    },
    components:{
      Carousel,
      CarouselSlide,
      ShipmentTrackingPopup
    },
    setup(){
    //ShipmentTracking Popup
    const ShipmentTrackingPopupTriggers = ref({
      ShipmentTrackingButtonTrigger: false
    });

    const ShipmentTrackingTogglePopup = (trigger) =>{
      ShipmentTrackingPopupTriggers.value[trigger] = !ShipmentTrackingPopupTriggers.value[trigger]
    }

    return{
      ShipmentTrackingTogglePopup,
      ShipmentTrackingPopupTriggers,
    }
  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.njls-dba-header{
  margin-top: 0 !important; 
  margin-bottom: 0 !important; 
  font-size: 1.5vw !important;
}
/****Popup */

.popup-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: drop .5s ease forwards;
  margin-bottom: -30px;
}

.popup-container h2{
  margin-top: -5px;
}

.popup-container input{
  margin-bottom: 1vw;
  height: 25px;
  border-radius: 5px;
  border: rgb(151, 151, 151) 1px solid;
  background-color: rgb(235, 235, 235);
  width: 40%;
}

/**************************************/
/* || Shipment Tracking Syles */

    .form_shiptrack{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        top: 6vw;
        right: 18vw;
    }

    .form_shiptrack h1{
        display: flex;
        font-size: 4.5vw;
        margin-bottom: 0;
    }

    .form_shiptrack p{
        display: flex;
        color: white;
        z-index: 5;
        font-size: 2vw;
        margin-top: 20px;
    }

    .form_shiptrack input{
        display: flex;
        padding: 1.5vw;
        width: 30vw;
        background-color: white;
        border: none;
        border-radius: 999px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        margin-top: 2px;
    }

    .form_shiptrack button{
        display: flex;
        padding: 15px 20px;
        background-color: #33f18a;
        border: none;
        border-radius: 999px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
    }

    .track_package{
        position: relative;
        display: flex;
        justify-content: center;
        animation: animate-homepage 1s ease;
        transition-delay: .25s;
    }

    @keyframes animate-homepage{
      from{margin-left: -5%;}
      to{margin-left: 0%;}
    }

    .track_package img{
        position: absolute;
        z-index: 1;
        width: 90vw;
        height: auto;
        animation: animate-header-image 1s ease forwards;
    }

    @keyframes animate-header-image {
      from{opacity: .75;}
      to{opacity: 1;}
    }

    .track_package h1{
        position:relative;
        z-index:5;
        color:white;
    }

    .track_package form{
        position:relative;
        z-index:5;
        color:white;
    }
    
/**************************************/
/* || LREX Paragraph Syles */
    .lrex_paragraph{
      margin-top: 15vw;
      z-index: 1;
      display: flex;
      justify-content: center;
    }

    .lrex_paragraph p{
      font-family: 'Work Sans', sans-serif;
      font-size: 1.8vw;
      width: 55vw;
      color: black;
      text-align: center;
      padding: 10px;
      border-radius: 25px;
    }

/**************************************/
/* || Slider Syles */
.carouselContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
/****************************/

.arrow_right{
  margin-left: 5vw;
  width: 20px;
  height: 20px;
  border-right: 10px solid #33f18a;
  border-top: 10px solid #33f18a;
  border-radius: 5px;
  transform: rotate(45deg);
  cursor: pointer;
}

.arrow_left{
  margin-right: 5vw;
  width: 20px;
  height: 20px;
  border-right: 10px solid #33f18a;
  border-top: 10px solid #33f18a;
  border-radius: 5px;
  transform: rotate(-135deg);
  cursor: pointer;
}

/**************************/
/* || Custom Solutions Syles */
.headline_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  margin-top: 5%;
  margin-bottom: 2%;
}

.headline_article{
  margin-right: 5%;
  width: 40vw;
  display: flex;
  flex-direction: column;
}

.headline_article p{
  display: flex;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  margin-top: 0;
  text-align: left;
  padding: 10px;
  border-radius: 25px;
}

.headline-bubble-container{
  width: 40vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.headline-bubble-inner{
  width: 80%;
  position: relative;
}

.bubble1{
	animation: animate-bubble 4s infinite;
	animation-delay: .25s;
}

.bubble2{
	animation: animate-bubble-two 4s infinite;
	animation-delay: .5s;
}

.bubble3{
	animation: animate-bubble-three 4s infinite;
	animation-delay: .75s;
}

@keyframes animate-bubble{
  0%{transform: translateY(0px);}
  50%{transform: translateY(-2.5px);}
  100%{transform: translateY(0px);}
}

@keyframes animate-bubble-two{
  0%{transform: translateY(0px);}
  50%{transform: translateY(2.5px);}
  100%{transform: translateY(0px);}
}

@keyframes animate-bubble-three{
  0%{transform: translateX(0px);}
  50%{transform: translateX(2.5px);}
  100%{transform: translateX(0px);}
}

.img-container-bubble img{
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
}

.text-container-bubble h2{
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  text-align: center;
  top: 48%;
  font-size: 2.2vw;
  font-weight: bold;
}


@media only screen and (max-width: 999px) and (min-width: 600px){
  .track_package{
    height: 60vh !important;
    overflow: hidden;
  }

  .track_package img{
    height: 60vh !important;
  }

  .lrex_paragraph{
    margin-top: 10% !important;
  }
}


@media only screen and (max-width: 1000px){
.njls-dba-header{
  font-size: 2.5vw !important;
  margin-top: 0 !important;
}
  /*****Popup */
.popup-container input{
  margin-bottom: 1vw;
  height: 25px;
  border-radius: 5px;
  border: rgb(151, 151, 151) 1px solid;
  background-color: rgb(235, 235, 235);
  width: 70%;
}

/**************************/
/* || Shipment Tracking Syles */

  .track_package h1{
    font-size: 8vw;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.534);
  }

  .track_package p{
    font-size: 4vw;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.534);
  }

  .track_package img{
    object-fit: cover;
    height: 50vh;
  }

  .track_package{
    height: 50vh;
    overflow: hidden;
  } 

  .delivery-truck-img{
    object-position: 72.5% 50%;
  }

  .form_shiptrack{
      left: 1vw;
      margin-bottom: 15vw;
      margin-top: 25vh;
  }

  .form_shiptrack input{
    width: 60vw;
    padding: 1.2vw;
  }

  .form_shiptrack h1{
    font-size: 7vw;
  }

  .locationDeliveryLink, .proofDeliveryLink{
    flex-direction: column;
  }

/**************************************/
/* || LREX Paragraph Syles */

    .lrex_paragraph p{
      margin-top: -3vw;
      margin-bottom: 5vw;
      font-size: 4vw;
      width: 80vw;
    }

/**************************************/
/**************************************/
/* || Slider Syles */

.arrow_right{
  margin-left: 1vw;
  width: 20px;
  height: 20px;
  border-right: 5px solid #33f18a;
  border-top: 5px solid #33f18a;
  border-radius: 5px;
  transform: rotate(45deg);
  cursor: pointer;
}

.arrow_left{
  margin-right: 1vw;
  width: 20px;
  height: 20px;
  border-right: 5px solid #33f18a;
  border-top: 5px solid #33f18a;
  border-radius: 5px;
  transform: rotate(-135deg);
  cursor: pointer;
}

/**************************/
/* || Custom Solutions Syles */
  .headline_container{
    flex-direction: column;
  }

  .headline_article{
    width: 80vw;
    margin-right: 0;
    margin-bottom: 5%;
  }

  .headline-bubble-container{
    width: 90vw;
    justify-content: center;
  }

  .text-container-bubble h2{
    font-size: 5.5vw;
  }

  .headline_article p{
    font-size: 4vw;
    text-align: center;
  }
}

@media only screen and (max-width: 950px){
/**************************/
/* || Shipment Tracking Syles */
  .form_shiptrack{
        right: 15vw;
    }
}

</style>
