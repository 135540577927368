<template>
<div v-if="submittingInfo">
        <LoadingData :headerMessage="headerMessage"/>
      </div>
  <div class="container">
      <div v-if="inquirySent" class="inquiry-sent">
          <h2>ZipShip Sent</h2>
          <div class="check-container">
              <div class="check"></div>
          </div>
          <p>Thank you for your using ZipShip!</p>
          <router-link class="router-link" to="/">Back to main page</router-link>
      </div>

      <AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
      
      <div v-if="!inquirySent" class="inner-container">
            
          <div class="text-container">
                <h1>ZipShip Information</h1>
                <div class="input-container">
                    <label for="name">Company Name</label>
                    <input class="contractor-input" v-model="ZipShipInput.companyName" name="name" type="text">
                </div>

                <div class="input-container">
                    <label for="name">Contact Name</label>
                    <input class="contractor-input" v-model="ZipShipInput.name" name="name" type="text">
                </div>
              
                <div class="input-container">
                    <label for="email">Email</label>
                    <input class="contractor-input" v-model="ZipShipInput.email" name="email" type="text">
                </div>

                <div class="input-container">
                    <label for="phone">Phone</label>
                    <input class="contractor-input" v-model="ZipShipInput.phone" name="phone" type="text">
                </div>

                <div class="input-container-text">
                    <!-- <label for="email">Phone</label> -->
                    <input v-model="ZipShipInput.sendText" name="sendText" type="checkbox" true-value="true" false-value="false">
                    <!-- <label for="sendText">I agree to receiving text messages from LREX</label> -->
                    <label for="sendText">Do not send me automated text messages</label>
                </div>

                <div class="input-container">
                    <label for="message">Message</label>
                    <textarea class="contractor-input" v-model="ZipShipInput.message" name="message" type="text"></textarea>
                </div>

                <!-- <div class="input-container">
                    <label for="download">Download and Sign the MVR Consent Form</label>
                    <a href="/MVR-CONSENT-FORM-LREX.docx" style="color: #32ccfe" name="download" download="MVRConsentForm">Click here to download</a>
                </div> -->

                <div class="file-input-container">
                    <div class="input-container">
                        <h2>Attach Labels</h2>
                        <label for="file">Label Input <small>(.pdf, .docx or .jpg)</small> </label>
                        <input id="fileInputLabels" class="fileInputLabels" @change="getBase64Labels()" accept=".jpg, .jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="file" type="file" multiple>
                    </div>

                    <div class="input-container">
                        <h2>Attach Documents</h2>
                        <label for="file">Document Input <small>(.pdf, .docx or .jpg)</small> </label>
                        <input id="fileInput" class="fileInput" @change="getBase64()" accept=".jpg, .jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="file" type="file" multiple>
                    </div>
                </div>
              
                
              
                <div class="input-container">
                    <!-- <button @click="InputStructure">Update Info</button> -->
                    <button class="main-button-green-white" @click="SubmitInfo">Submit</button>
                </div>

          </div>

            <!-- src\assets\FinalImages\AdobeStock_WomanDriver.jpeg -->
          <div class="image-container">
              <div class="image-text-info">
                <!-- <h1>LREX</h1> -->
                <img class="logo" src="../../assets/LREXHeaderLogo.jpg" alt="LREX" style="width: 80px; border-radius: none !important;">
                <p style="font-weight: bold;">
                    Local - Regional - Express
                </p> 
                <p style="margin-bottom: 5px">
                    You hit send and we <span style="font-weight: bold;">print, package</span> and <span style="font-weight: bold;">deliver</span> by the end of the next business day. <br>
                    <span style="font-weight: bold;">ZipShip’s next day service is available for material received between 9am and 4:30pm, Monday through Friday.</span> <br>
                    <!-- <h3 class="cost-header" style="font-weight: bold;">Zipship costs?</h3> <br> -->
                </p>
                    <h3 class="cost-header">Zipship costs?</h3>
                <p style="margin-top: 5px">
                    <span style="font-weight: bold;">Your normal per-shipment charge </span> <br>
                    <span style="font-weight: bold;">First 25 pages printed free, then $.25/page  </span> <br> <br>
                    Contact us at: <span style="font-weight: bold;">zipship@lrex.com</span>
                </p>
              </div>
              
              <video class="video-zipship" autoplay="" controlslist="nodownload nofullscreen noremoteplayback" muted="" playsinline="">
                <source src="../../assets/FinalImages/zipship-video.mp4" type="video/mp4">
              </video>
              <!-- <img class="woman-driver-image" src="../../assets/FinalImages/AdobeStock_WomanDriver.jpeg" alt=""> -->
              <div class="image-text"><div class="image-text-inner"><p>ZipShip</p></div></div>
          </div>
          <!-- {{ZipShipInput}} -->
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import AlertUser from '../Popups/AlertUser.vue';
import LoadingData from '../Popups/LoadingData.vue';
export default {
    data(){
        return{
            ZipShipInput: {
                companyName: '',
                name: '',
                email: '',
                phone: '',
                sendText: false,
                message: '',
                base64FileArray: [],
                base64FileArrayLabels: []
            },
            submittingInfo: false,
            inquirySent: false,
            base64Array: [],
            fileType: false,
            toggleAlertBox: false,
            alertMessage: "",
            headerMessage: ""
        }
    },
    components:{
        AlertUser,
        LoadingData
    },
    mounted(){
        this.scrollToTop();
    },
    methods:{
            /**
             * Validation of user input as they create a new address
             * Validates input values and edit values
             */
            validateEmail(){
                if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.ZipShipInput.email) == true){
                    return true;
                }else{
                    this.alertMessage = "Please enter a valid email.";
                    this.toggleAlertBox = true;
                    this.addressBookInput.email = "";
                    return false;
                }
            },
            validatePhone(){
                if(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.ZipShipInput.phone) == true){
                    let trimPhone = this.ZipShipInput.phone.replace(/[^0-9]/g, '');
                    let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                    this.ZipShipInput.phone = trimPhoneDashes;
                    return true;
                }else{
                    this.alertMessage = "Please enter a valid phone number.";
                    this.toggleAlertBox = true;
                    this.addressBookInput.phone = "";
                    return false;
                }
            },
            async getBase64(){
                var fileInput = document.getElementById("fileInput");
                var fileList = [];
                this.ZipShipInput.base64FileArray = [];

                for(var i = 0; i < fileInput.files.length; i++){
                    await this.setupReader(fileInput.files[i], i)
                    var fileArrayInput = {base64File: "", isPDF: false, isDOCX: false, isJPEG: false}
                    fileList.push(fileInput.files[i].name)
                    var filePathName = fileInput.files[i].name;
                    var fileExtension = filePathName.split('.');
                    var fileExtensionType = fileExtension[fileExtension.length - 1];

                    switch(fileExtensionType.toLowerCase()){
                        case "docx":
                            fileArrayInput.isDOCX = true;
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isJPEG = false;
                            this.fileType = true;
                            break;
                        case "pdf":
                            fileArrayInput.isPDF = true;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = false;
                            this.fileType = true;
                            break;
                        case "jpg":
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = true;
                            this.fileType = true;
                            break;
                        case "jpeg":
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = true;
                            this.fileType = true;
                            break;
                        default:
                            this.toggleAlertBox = true;
                            this.alertMessage = "Please add the correct file type.";
                            this.fileType = false;
                            this.ZipShipInput.base64FileArray = null;
                            break;
                    }
                    this.ZipShipInput.base64FileArray.push(fileArrayInput)
                    console.log(this.ZipShipInput)
                }
            },
            async getBase64Labels(){
                let fileInput = document.getElementById("fileInputLabels");
                let fileList = [];
                this.ZipShipInput.base64FileArrayLabels = [];

                for(let i = 0; i < fileInput.files.length; i++){
                    await this.setupReaderLabels(fileInput.files[i], i)
                    let fileArrayInput = {base64File: "", isPDF: false, isDOCX: false, isJPEG: false}
                    fileList.push(fileInput.files[i].name)
                    let filePathName = fileInput.files[i].name;
                    let fileExtension = filePathName.split('.');
                    let fileExtensionType = fileExtension[fileExtension.length - 1];

                    switch(fileExtensionType.toLowerCase()){
                        case "docx":
                            fileArrayInput.isDOCX = true;
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isJPEG = false;
                            this.fileType = true;
                            break;
                        case "pdf":
                            fileArrayInput.isPDF = true;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = false;
                            this.fileType = true;
                            break;
                        case "jpg":
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = true;
                            this.fileType = true;
                            break;
                        case "jpeg":
                            fileArrayInput.isPDF = false;
                            fileArrayInput.isDOCX = false;
                            fileArrayInput.isJPEG = true;
                            this.fileType = true;
                            break;
                        default:
                            this.toggleAlertBox = true;
                            this.alertMessage = "Please add the correct file type.";
                            this.fileType = false;
                            this.ZipShipInput.base64FileArray = null;
                            break;
                    }
                    this.ZipShipInput.base64FileArrayLabels.push(fileArrayInput)
                    // console.log(this.ZipShipInput)
                }
            },
            SendInfo(){ 
                let validEmail = this.validateEmail();
                let validPhone = this.validatePhone();

                if((validEmail && validPhone)){
                    if((this.fileType == true) && (this.ZipShipInput.base64FileArray != null) && (this.ZipShipInput.base64FileArrayLabels != null) && (this.ZipShipInput.base64FileArray.length > 0) && (this.ZipShipInput.base64FileArrayLabels.length > 0)){
                    this.submittingInfo = true;
                    this.headerMessage = "Submitting Information";
                    axios.post('https://v3vusb9hl5.execute-api.us-east-1.amazonaws.com/prod/sendzipship', this.ZipShipInput,{})
                        .then((response)=>{
                            //console.log(response)
                            if(response.status == 200){
                                this.inquirySent = true;
                            }else{
                                this.toggleAlertBox = true;
                                this.alertMessage = "Error submitting information.";
                            }
                        })
                        .catch(error => {
                            alert(error); 
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error submitting information.";
                        }).finally(()=>{this.submittingInfo = false})
                    }else{
                        this.toggleAlertBox = true;
                        this.alertMessage = "Please add the correct file type.";
                    }
                }
            },
            async SubmitInfo(){
                this.scrollToTop();
                this.SendInfo();
            },
            async setupReader(file, index){
                var reader = new FileReader();
                    reader.onloadend= () => {
                    var base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
                    this.ZipShipInput.base64FileArray[index].base64File = base64String;
                    }

                reader.readAsDataURL(file);
            },
            async setupReaderLabels(file, index){
                var reader = new FileReader();
                    reader.onloadend= () => {
                    var base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
                    this.ZipShipInput.base64FileArrayLabels[index].base64File = base64String;
                    }

                reader.readAsDataURL(file);
            },
            scrollToTop(){
                window.scrollTo(0,0);
            },
            closeAlertBox(toggleAlertBox){
                this.toggleAlertBox = toggleAlertBox;
            }
    }
}
</script>

<style scoped>
@import "../../styles/button-styles.css";

    textarea, input{
        font-family: 'Work Sans', sans-serif;;
    }

    .container{
        width: 100%;
        display: flex;
        justify-content: center;
        animation: animate-container 1s ease;
    }

    @keyframes animate-container{
        from{margin-left: -10%;}
        to{margin-left: 0%;}
    }

    .inner-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80%;
    }

    .text-container{
        width: 50%;
        flex-direction: column;
        text-align: left;
    }

    .text-container h1{
        background-color: #fe804d;
        padding: 5px 10px;
        color: #fff;
        border-radius: 50px;
        width: fit-content;
    }

    .cost-header{
        background-color: #fe804d;
        padding: 5px 10px;
        color: #fff;
        border-radius: 50px;
        width: fit-content;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .image-container{
        width: 50%;
        margin-left: 5%;
    }

    .image-text-info{
        text-align: left;
    }

    .video-zipship{
        width: 90%;
        border-radius: 500px 500px 0px 0px;
        position: relative;
        z-index: 1;
    }

    /* .woman-driver-image{
        width: 90%;
        border-radius: 500px 500px 0px 0px;
        position: relative;
        z-index: 1;
    } */

    .image-text{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .image-text-inner{
        position: relative;
        z-index: 2;
        margin-top: -8.5%;
        display: flex;
        background-color: #32ccfe;
        color: #fff;
        width: 7em;
        height: 7em;
        border-radius: 200px;
        align-items: center;
        justify-content: center;
    }

    .input-container-text{
        width: 100%;
        margin: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .input-container-text input{
        margin-right: 20px;
    }

    .input-container{
        display: flex;
        margin: 5px;
        width: 100%;
        flex-direction: column;
    }

    .input-container button{
        font-size: 20px;
        width: 50%;
    }

    .input-container label{
        width: 100%;
    }

    .input-container h2{
        background-color: #33f18a;
        font-size: medium;
        color: #000;
        padding: 5px 10px;
        border-radius: 999px;
        margin: 0;
        margin-bottom: 5px;
        width: fit-content;
    }

    .file-input-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .contractor-input{
        padding: 10px;
        width: 100%;
        font-size: 15px;
        border: 1px solid rgba(0, 0, 0, 0.336);
        border-radius: 5px;
        outline: none;
    }


    .fileInput, .fileInputLabels{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    input[type=file]::file-selector-button {
        border: none;
        padding: 5px;
        border-radius: 15px;
        background-color: #32ccfe;
        transition: 1s;
        color: #fff;
        cursor: pointer;
    }

    input[type=file]::file-selector-button:hover {
        background-color: #2dbbeb;
        transition: 1s;
    }

    /* Check Mark Animation */
    .check {
        height: 25px;
        width: 12.5px;
        border-width: 7px;
        transform: rotate(45deg);
        border-bottom: 7px solid #fff;
        border-right: 7px solid #fff;
        margin-bottom: 7px;
    }

    @keyframes animate-check-container {
        0%{
            transform: rotate(0deg) scale(10%);
            background-color: #fff;
        }
        100%{
            transform: rotate(360deg) scale(100%);
        }
    }

    @keyframes animate-inquiry-sent{
        from{
            margin-left: -20px;
            opacity: 50%;
        }
        to{
            margin-left: 0;
            opacity: 100%;
        }
    }

    .inquiry-sent{
        animation: animate-inquiry-sent .5s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f8f8f8;
        border-radius: 15px;
        padding: 10px;
    }

    .inquiry-sent p{
        padding: 5px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.089) 0px 3px 8px;
    }

    .check-container{
        width: 50px;
        height: 50px;
        border: 5px solid #33f18a;
        background-color: #33f18a;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: animate-check-container 1s ease;
    }

    .router-link{
        text-decoration: none;
        color: white;
        background-color: #32ccfe;
        padding: 5px;
        border-radius: 15px;
        transition-duration: .5s;
    }

    .router-link:hover{
        background-color: #2dbbeb;
        transition-duration: .5s;
    }

    @media screen and (max-width: 1350px) {
        .file-input-container{
            flex-direction: column;
        }
    }

    @media screen and (max-width: 1000px) {

        .inner-container{
            flex-direction: column;
            width: 95%;
        }

        .text-container{
            width: 100%;
            margin-bottom: 5%;
        }

        .image-container{
            margin-left: 0;
            width: 90%;
        }

        .contractor-input{
            width: 90%;
        }

        
    }

    @media screen and (max-width: 600px) {
        .text-container h1{
            font-size: 25px;
        }

        .image-text-inner{
            width: 90px;
            height: 90px;
        }
    }
</style>